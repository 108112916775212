import {UTIL_CONSTS} from "../constants";

export const initialState = {
    requestLoading: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case UTIL_CONSTS.START_LOADING:
            return {
                ...state,
                requestLoading: true
            };
        case UTIL_CONSTS.END_LOADING:
            return {
                ...state,
                requestLoading: false
            };
        default:
            return state;
    }
}