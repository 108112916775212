import React from "react";
import { ShareIcon} from "../../../../assets/images";

//Import Assets
import "./newsCard.scss";
import {generateImageMediaUrl} from "../../../../utils/generateMediaUrl";
import moment from "moment";
import {Link} from "react-router-dom";
import {getUrlWithLocal} from "../../../../utils/getUrlWithLocal";

function NewsCard(props){
    const {item} = props

    return <Link to={getUrlWithLocal(`blog/${item?.slug}`)} className={'news-block'}>
        <div className={'news-block-img'}>
            <img src={generateImageMediaUrl(item?.mediaMain?.path)}/>
        </div>
        <div className={'news-block-info'}>
            <div className={'news-date'}>
                {moment(item?.date).format("DD.MM.YYYY")}
            </div>
            <div className={'news-title-wrapper'}>
                <div className={'news-block-title'}>
                    {item?.title}
                </div>
                <Link to={getUrlWithLocal(`/blog/${item?.slug}`)} className={'news-block-link'}>
                    <ShareIcon/>
                </Link>
            </div>
            <div className={'news-block-description'}>
                {item?.description}
            </div>
        </div>
    </Link>
}
export default NewsCard