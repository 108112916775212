// import packages
import React from "react";
import Slider from "react-slick";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

//Import styles
import "../../assets/styles/homepage/mainBlock.scss";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {
    BgIcon, CallIcon, ChatIcon,
    ContactGirlIcon,
    ContactHeaderIcon,
    MessageIcon, ResponsiveBgIcon,
    TelegramIcon,
    ViberIcon, VoiceIcon,
    WhatsAppIcon
} from "../../assets/images";
import {CALENDAR_LINK} from "../../constants/constType";

//Import Components

// Import utils


function MainBlock({contacts,staticTexts}){
    return <div className={'mainBlock'}>
        <div className={'bgBlock'}>
            <BgIcon/>
        </div>
        <div className={'responsiveBg-block'}>
            <ResponsiveBgIcon/>
        </div>
        <div className={'info-block'}>
            <div className={"text"}>
                <div className={'title'}>
                    {staticTexts?.homepage_main_block_title}
                </div>
                <div className={'description'}>
                    {staticTexts?.homepage_main_block_description}
                </div>
            </div>

            <div className={'contact-block'}>
                <div className={'messenger'}>
                    <ContactHeaderIcon/>
                    <span className={'girl'}>
                        <ContactGirlIcon/>
                    </span>
                    <a href={`viber://chat?number=${contacts?.viber}`} target={'_blank'} className={'viber'}>
                        <ViberIcon/>
                    </a>
                    <a href={`https://telegram.me/${contacts?.telegram}`} target={'_blank'} className={'telegram'}>
                        <TelegramIcon/>
                    </a>
                    <a href={`https://wa.me/${contacts?.whatsapp}`} target={'_blank'} className={'whatsApp'}>
                        <WhatsAppIcon/>
                    </a>
                    <span className={'message'}>
                        <MessageIcon/>
                    </span>
                    <div className={'call-block'}>
                        <a href={`tel:${contacts?.phoneNumber}`} className={'telephone'}>
                            <CallIcon/>
                        </a>
                    </div>
                    <span className={'chat'}>
                        <ChatIcon/>
                    </span>
                </div>
            </div>

            <a className={'consultation'} href={CALENDAR_LINK} target={'_blank'}>
                {staticTexts?.homepage_consultation_btn_title}
            </a>
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
    ])
};
export default connect(mapStateToProps)(MainBlock);