// import packages
import React, {Component} from "react";
import Slider from "react-slick";

//Import styles
import "../../assets/styles/homepage/services.scss";

//Import Components
import {SliderNextButton, SliderPrevButton} from "../uiElements/buttons";
import {Link} from "react-router-dom";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";
import {openUrl} from "../../utils/helperFunctions";
import {
    Bg1Icon, Bg2Icon, Bg3Icon, Bg4Icon, MoreArrIcon,
    NumberIcon, ResponsiveBg1Icon, ResponsiveBg2Icon, ResponsiveBg3Icon,
    Services1Icon,
    Services2Icon,
    Services3Icon,
    SmallCallIcon,
    SmallMessIcon
} from "../../assets/images";
import {getUrlWithLocal} from "../../utils/getUrlWithLocal";

// Import utils

function Services({staticTexts}){
    return <div className={'services'}>
        <div className={'header'}>
            <div className={'text'}>{staticTexts?.homepage_services_subtitle}</div>
            <div className={'title'}>{staticTexts?.homepage_services_title}</div>
            <div className={'description'}>{staticTexts?.homepage_services_description}</div>
        </div>
        <div className={'services-wrapper'}>
            <div className={'service-block'}>
                <div className={'text-block'}>
                    <div className={'bg-block'}>
                        <Bg1Icon/>
                    </div>
                    <div className={'responsiveBg-block'}>
                        <ResponsiveBg1Icon/>
                    </div>
                    <SmallMessIcon/>
                    <div className={'blocks-title'}>
                        {staticTexts?.homepage_services_block1_title}
                    </div>
                    <div className={'blocks-description'}>
                        {staticTexts?.homepage_services_block1_description}
                    </div>
                    <Link to={getUrlWithLocal(`/services/messaging`)} className={'more-info'}>
                        {staticTexts?.homepage_more_info_btn} <MoreArrIcon/>
                    </Link>
                </div>
                <div className={'img-block'}>
                    <Services1Icon/>
                </div>
            </div>
            <div className={'service-block'}>
                <div className={'img-block'}>
                    <Services2Icon/>
                </div>
                <div className={'text-block'}>
                    <div className={'bg-block'}>
                        <Bg2Icon/>
                    </div>
                    <div className={'responsiveBg-block'}>
                        <ResponsiveBg2Icon/>
                    </div>
                    <SmallCallIcon/>
                    <div className={'blocks-title'}>
                        {staticTexts?.homepage_services_block2_title}
                    </div>
                    <div className={'blocks-description'}>
                        {staticTexts?.homepage_services_block2_description}
                    </div>
                    <Link to={getUrlWithLocal(`/services/short-number`)} className={'more-info'}>
                        {staticTexts?.homepage_more_info_btn} <MoreArrIcon/>
                    </Link>
                </div>
            </div>
            <div className={'service-block'}>
                <div className={'text-block'}>
                    <div className={'bg-block'}>
                        <Bg3Icon/>
                    </div>
                    <div className={'responsiveBg-block'}>
                        <ResponsiveBg3Icon/>
                    </div>
                    <SmallMessIcon/>
                    <div className={'blocks-title'}>
                        {staticTexts?.homepage_services_block3_title}
                    </div>
                    <div className={'blocks-description'}>
                        {staticTexts?.homepage_services_block3_description}
                    </div>
                    <Link to={getUrlWithLocal(`/services/call-center`)} className={'more-info'}>
                        {staticTexts?.homepage_more_info_btn} <MoreArrIcon/>
                    </Link>
                </div>
                <div className={'img-block'}>
                    <Services3Icon/>
                </div>
            </div>
        </div>
    </div>
}

export default Services;
