// import packages
import React, {useRef} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {CountUp} from 'use-count-up';
import VisibilitySensor from "react-visibility-sensor";

//Import assets
import '../../assets/styles/homepage/about.scss'

import {getPropsFromState} from "../../redux/mapStateToProps";


function About({staticTexts,about}){
    return <div className={'about'}>
        <div className={'header-block'}>
            <div className={'title'}>
                {staticTexts?.homepage_about_block_title}
            </div>
            <div className={'description'}>
                {staticTexts?.homepage_about_block_description}
            </div>
        </div>

        <div className={'about-info'}>
            <div className={'info-block'}>
                <span className={'parametric'}>{about?.service}</span>
                <span className={'parametric-text'}>{staticTexts?.homepage_about_service_title}</span>
            </div>
            <div className={'info-block'}>
                <span className={'parametric'}>{about?.certificate}</span>
                <span className={'parametric-text'}>{staticTexts?.homepage_about_certificate_title}</span>
            </div>
            <div className={'info-block'}>
                <span className={'parametric'}>{about?.inMarket}</span>
                <span className={'parametric-text'}>{staticTexts?.homepage_about_years_title}</span>
            </div>
            <div className={'info-block'}>
                <span className={'parametric'}>{about?.workers}</span>
                <span className={'parametric-text'}>{staticTexts?.homepage_about_employee_title}</span>
            </div>
            <div className={'info-block'}>
                <span className={'parametric'}>{about?.availability}</span>
                <span className={'parametric-text'}>{staticTexts?.homepage_about_availability_title}</span>
            </div>
            <div className={'info-block'}>
                <span className={'parametric'}>{about?.partners}</span>
                <span className={'parametric-text'}>{staticTexts?.homepage_about_customer_title}</span>
            </div>
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'about',
    ])
};

export default connect(mapStateToProps)(About);