// import packages
import React, {Component, useEffect} from "react";
import {connect} from "react-redux";

//Import assets
import '../assets/styles/containers/team.scss';

//Import components
import {PageStart} from "../components/uiElements/PageStart";

//Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {GetMembers} from "../redux/actions";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";
import MembersCard from "../components/uiElements/cards/membersCard/MembersCard";
import {FeatherHeartIcon, FeatherSmileIcon, FeatherStarIcon} from "../assets/images";
import Subscribe from "../components/Contacts";

function Team(props){
    const {members,requestLoading,staticTexts} = props

    useEffect(() => {
        props.GetMembers()
    },[props.selectedLanguage?.code])

    const featherData = [
        {
            id:1,
            icon:<FeatherSmileIcon/>,
            title:`${staticTexts?.team_feathers_block1_title}`,
            description:`${staticTexts?.team_feathers_block1_description}`
        },
        {
            id:2,
            icon:<FeatherHeartIcon/>,
            title:`${staticTexts?.team_feathers_block2_title}`,
            description:`${staticTexts?.team_feathers_block2_description}`
        },
        {
            id:3,
            icon:<FeatherStarIcon/>,
            title:`${staticTexts?.team_feathers_block3_title}`,
            description:`${staticTexts?.team_feathers_block3_description}`
        }
    ]

    return <div className={'team-wrapper'}>
        <div className={'team-block'}>
            <div className={'team-main-wrapper'}>
                <div className={'team-main'}>
                    <div className={'team-main-subtitle'}>
                        {members?.subTitle}
                    </div>
                    <div className={'team-main-title'}>
                        {members?.title}
                    </div>
                    <div className={'team-main-description'}>
                        {members?.description}
                    </div>
                </div>
            </div>
            <div className={'members-wrapper'}>
                {
                    members?.members?.length && members?.members.map(item => {
                        return <MembersCard item={item} key={item.id}/>
                    })
                }
            </div>
            <div className={'teams-feathers-wrapper'}>
                <div className={'teams-feathers-info'}>
                    <div className={'feathers-subTitle'}>
                        {staticTexts?.team_page_subTitle}
                    </div>
                    <div className={'feathers-title'}>
                        {staticTexts?.team_page_title}
                    </div>
                    <div className={'feathers-description'}>
                        {staticTexts?.team_page_description}
                    </div>
                </div>

                <div className={'feathers-block'}>
                    {
                        featherData.map(item => {
                            return <div className={'feather'} key={item.id}>
                                <div className={'feather-icon'}>
                                    {item?.icon}
                                </div>
                                <div className={'feather-title'}>
                                    {item?.title}
                                </div>
                                <div className={'feather-description'}>
                                    {item?.description}
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>

        <Subscribe requestLoading={requestLoading} staticTexts={staticTexts}/>

    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'selectedLanguage',
        'members',
        'staticTexts',
        'requestLoading'
    ])
};

const mapDispatchToProps = {
    GetMembers
};

export default connect(mapStateToProps, mapDispatchToProps)(Team);
