// import packages
import React, {Component, useState} from "react";
import Slider from "react-slick";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

//Import styles
import "../../assets/styles/homepage/project.scss";

//Import Components
import { Tabs } from 'antd';

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {openUrl} from "../../utils/helperFunctions";
import {MoreArrIcon, ProjectsBgIcon, ProjectsIcon} from "../../assets/images";
import {CALENDAR_LINK} from "../../constants/constType";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";

function Project({projects,staticTexts}){
    const [tabPosition, setTabPosition] = useState('right');

    return <div className={'description-wrapper'}>
        <div className="description-content">
            <div className={'text-content'}>
                <p>{staticTexts?.homepage_projects_subtitle}</p>
                <div className="title">{staticTexts?.homepage_projects_title}</div>
                <div className="text">{staticTexts?.homepage_projects_description}</div>
            </div>
            <span className={'bg-wrapper'}>
                                   <ProjectsBgIcon/>
                            </span>
        </div>

        <div className={'projects-block'}>
            <Tabs
                tabPosition={tabPosition}
                items={projects.length && projects?.map((item,index) => {
                    return {
                        label: <div className={'project'}>
                            <div className={'project-title'}>
                                {item?.title}
                            </div>
                            <div className={'project-description'}>
                                {item?.description}
                            </div>
                            { index < 2 &&
                                <a href={item?.link} target={'_blank'} className={'project-more-news'}>
                                    {staticTexts?.homepage_more_info_btn} <MoreArrIcon/>
                                </a>
                            }
                        </div>,
                        key: item?.id,
                        children: <div className={'img-wrapper'}>
                            <span className={'img'}>
                                <img src={generateImageMediaUrl(item?.mediaMain?.path)}
                                     title={item?.mediaMain?.title ? item?.mediaMain?.title : ''}
                                     alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}
                                     className="about-img"/>
                            </span>
                        </div>
                    };
                })}
            />
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
    ])
};

export default connect(mapStateToProps)(Project);
