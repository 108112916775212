import {
    request,
    _urlStaticTexts,
    _urlSlider,
    _urlPartners,
    _urlContacts,
    _urlLanguage,
    _urlCustomPages,
    _urlSubscribers,
    _urlCallRequest,
    _urlMenu,
    _urlAbout,
    _urlServices,
    _urlWorks,
    _urlMembers,
    _urlFeatures,
    _urlCategories,
    _urlBlogs,
     _urlProjects,
} from "../api";
import {GENERAL_CONSTS, UTIL_CONSTS} from "../constants";

export const getBrowserLanguage = () => {
    return navigator.language.split('-')[0] || null;
};


export const GetLanguages = () => {
    const requestData = {
        url: `${_urlLanguage}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async(res) => {
                const data = res && res.data;
                await dispatch({
                    type: GENERAL_CONSTS.GET_LANGUAGES,
                    payload: data
                })
            })
    };
};

export const GetStaticTexts = () => {
    const requestData = {
        url: `${_urlStaticTexts}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_STATIC_TEXTS,
                    payload: data
                })
            })
    };
};

export const ChangeLanguage = (data) => {
    return dispatch => {
        dispatch({
            type: GENERAL_CONSTS.CHANGE_LANGUAGE,
            payload: data
        })
    }
};


export const GetCategories = () => {
    const requestData = {
        url: `${_urlCategories}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_CATEGORIES,
                    payload: data
                })
            })
    };
};


export const GetBlogs = ({
                             reset = true,
                             offset = 3,
                             category,
                             limit = 6,
                         } = {}) => {
    let url = `${_urlBlogs}?offset=${offset}&limit=${limit}`;
    category && (url += `&category=${category}`);
    const requestData = {
        url,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_BLOGS,
                    payload: {
                        data,
                        reset,
                        hasMore: data.length === limit,
                    }
                })
            })
    };
};

export const GetLastBlogs = ({
                                     reset = true,
                                     offset = 0,
                                     category,
                                     limit = 3,
                                 } = {}) => {
    let url = `${_urlBlogs}?offset=${offset}&limit=${limit}`;
    category && (url += `&category=${category}`);
    const requestData = {
        url,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_LAST_NEWS,
                    payload: {
                        data,
                        reset,
                        hasMore: data.length === limit,
                    }
                })
            })
    };
};

export const GetSingleBlog = (slug) => {
    const requestData = {
        url: `${_urlBlogs}/${slug}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_SINGLE_BLOG,
                    payload: data
                })
            })
    };
};


export const GetPartners = () => {
    const requestData = {
        url: `${_urlPartners}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_PARTNERS,
                    payload: data
                })
            })
    };
};
export const GetFeatures = () => {
    const requestData = {
        url: `${_urlFeatures}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_FEATURES,
                    payload: data
                })
            })
    };
};

export const GetAbout = () => {
    const requestData = {
        url: `${_urlAbout}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_ABOUT,
                    payload: data
                })
            })
    };
};

export const GetMembers = () => {
    const requestData = {
        url: _urlMembers,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_MEMBERS,
                    payload: data
                })
            })
    };
};

export const GetContacts = () => {
    const requestData = {
        url: `${_urlContacts}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_CONTACTS,
                    payload: data
                })
            })
    };
};

export const SendEmail = (data) => {
    const requestData = {
        url: `${_urlContacts}`,
        method: "POST",
        data
    };
    return dispatch => {
        dispatch({type:UTIL_CONSTS.START_LOADING});
        return request(requestData).finally(() => {
            dispatch({type:UTIL_CONSTS.END_LOADING})
        })
    }
};
export const sendSubscribe = (data) => {
    const requestData = {
        url: `${_urlSubscribers}`,
        method: "POST",
        data
    };
    return dispatch => {
        dispatch({type:UTIL_CONSTS.START_LOADING});
        return request(requestData).finally(() => {
            dispatch({type:UTIL_CONSTS.END_LOADING})
        })
    }
};

export const callRequest = (data) => {
    const requestData = {
        url: `${_urlCallRequest}`,
        method:"POST",
        data
    };
    return dispatch => {
        return request(requestData).finally(() =>{})
    }
}
export const GetCustomPages = (url) => {
    const requestData = {
        url: `${_urlCustomPages}/${url}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_CUSTOM_PAGES,
                    payload: data
                })
            })
    };
};


export const ClearCustomPage = () => {

    return async dispatch => {
        await dispatch({
            type: GENERAL_CONSTS.CLEAR_CUSTOM_PAGE
        })
    };
};

export const GetServices = (url) => {
    const requestData = {
        url: `${_urlServices}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_SERVICES,
                    payload: data
                })
            })
    };
};

export const GetServicesById = (id) => {
    const requestData = {
        url: `${_urlServices}/${id}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_SERVICES_BY_ID,
                    payload: data
                })
            })
    };
};

export const GetProjects = () => {
    const requestData = {
        url: `${_urlProjects}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_PROJECT,
                    payload: data
                })
            })
    };
};


