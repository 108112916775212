// Import packages
import React, {Component} from "react";
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";

//Import styles
import "../assets/styles/containers/layout.scss";
import {ReactComponent as Call} from '../assets/images/icons/ic_phone.svg';
// Import components
import MetaTags from "../components/MetaTags";
import Header from "../components/Header";
import Footer from "../components/Footer";
import RightMenu from "../components/RightMenu";

// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {
    GetLanguages,
    GetContacts,
    GetAbout,
    GetServices,
    GetPartners,
    GetFeatures,
    GetStaticTexts,
    GetMembers,
    GetProjects,
    GetBlogs,
    GetLastBlogs
} from "../redux/actions";
import CallModel from "../components/callModel/CallModel";

class Layout extends Component {
    constructor() {
        super();
        this.state = {
            mobileMenuIsOpen: false,
            callModelOpen:false,
            changeLanguage:false,
        };
        this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
        this.toggleCallBtn = this.toggleCallBtn.bind(this);
        this.toggleLanguage=this.toggleLanguage.bind(this);
    }

    async componentDidMount() {
       await this.props.GetLanguages().finally(() => {
            this.props.GetStaticTexts();
        });

        this.props.GetAbout();
        this.props.GetServices();
        this.props.GetPartners();
        this.props.GetContacts();
        this.props.GetProjects();
        this.props.GetBlogs();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {location, selectedLanguage} = this.props;
        if (location?.pathname !== prevProps?.location?.pathname) {
            window.scrollTo(0, 0);
            this.setState({
                mobileMenuIsOpen: false,
            })
        }

        if (selectedLanguage && prevProps.selectedLanguage && selectedLanguage.code !== prevProps.selectedLanguage.code) {
            this.props.GetAbout();
            this.props.GetServices();
            this.props.GetPartners();
            this.props.GetStaticTexts();
            this.props.GetContacts();
            this.props.GetProjects();
            this.props.GetBlogs()
            this.props.GetLastBlogs()
            // this.props.GetMembers();
        }
    }

    toggleMobileMenu() {
        this.setState({
            mobileMenuIsOpen: !this.state.mobileMenuIsOpen,
        })
    }
    toggleCallBtn() {
        this.setState({
            callModelOpen: !this.state.callModelOpen,
        })
    }
    toggleLanguage() {
        this.setState({
            changeLanguage: !this.state.changeLanguage,
        })
    }

    render() {
        const {staticTexts, children, categories, menuList} = this.props;
        const {mobileMenuIsOpen} = this.state;

        return <div className="layout-wrapper">
            <MetaTags/>
            <Header
                mobileMenuIsOpen={mobileMenuIsOpen}
                categories={categories}
                toggleMobileMenu={this.toggleMobileMenu}
                // changeLanguage={this.state.changeLanguage}
                // toggleLanguage={this.toggleLanguage}
                staticTexts={staticTexts}
            />
            <div className={`main-content`}>
                {children}
            </div>
            {/*<button className='callBtn' onClick={this.toggleCallBtn}>*/}
            {/*    <Call title=''/>*/}
            {/*</button>*/}
            <Footer/>
            <RightMenu
                menuList={menuList}
                toggleMobileMenu={this.toggleMobileMenu}
                // changeLanguage={this.state.changeLanguage}
                // toggleLanguage={this.toggleLanguage}
                categories={categories}
                className={mobileMenuIsOpen ? 'expand_on' : ''}
            />
            <CallModel  toggleCallBtn={this.toggleCallBtn}
                        callModelOpen={this.state.callModelOpen}/>
        </div>
    }
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'languages',
        'selectedLanguage',
        'categories',
        'staticTexts',
        'menuList',
        'blogs'
    ])
};

const mapDispatchToProps = {
    GetLanguages,
    GetAbout,
    GetServices,
    GetPartners,
    GetFeatures,
    GetContacts,
    GetStaticTexts,
    GetMembers,
    GetProjects,
    GetBlogs,
    GetLastBlogs
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
