// import packages
import React, {Component, useEffect, useState} from "react";
import {connect} from 'react-redux';
import {history} from "../configs/history";
import isEmail from "validator/es/lib/isEmail";

//Import assets
import '../assets/styles/containers/contacts.scss';

//Import Components
import {PageStart} from "../components/uiElements/PageStart";
import {InputGroup} from "../components/uiElements/inputGroup";
import {LinkButton} from "../components/uiElements/buttons";

// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {SendEmail} from "../redux/actions";
import Locations from "./Locations";
import {FullscreenControl, Map, Placemark, TypeSelector, YMaps} from "react-yandex-maps";
import {Checkbox, Col, Row, Select} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import countriesPhone from '../countriesCode/CountryCodes.json'
import {ContactCallIcon, ContactLocationIcon, ContactMessageIcon} from "../assets/images";
import Subscribe from "../components/Contacts";

function Contact(props){
    const {staticTexts,contacts,requestLoading} = props
    const [fieldsData,setFieldsData] = useState({
        firstName:'',
        lastName:'',
        email:'',
        phone:'+374',
        message:'',
        services:[],
    })
    const [error,setError] = useState({
        firstName:false,
        lastName:false,
        email:false,
        phone:false,
        message:false,
        services:false,
    })
    const SERVICES = [
        {
            name:`${staticTexts?.contacts_page_services_correspondence}`,
            id:'63f3575affa56a401ba56e10'
        },
        {
            name:`${staticTexts?.contacts_page_services_call}`,
            id:'63f3575affa56a401ba56e0e'
        },
        {
            name:`${staticTexts?.contacts_page_services_number}`,
            id:'63f3575affa56a401ba56e11'
        },
        {
            name:`${staticTexts?.contacts_page_services_other}`,
            id:''
        },
    ]
    const [country,setCountry] = useState('AM')

    useEffect(()=> {
        if(country !== "AM"){
            setFieldsData({
                ...fieldsData,
                phone:country,
            })
        }
    },[country])

    function validate() {
        const error = {};
        let result = true;
        Object.keys(fieldsData).forEach(key => {
            if (!fieldsData[key] || (key === 'email' && !isEmail(fieldsData?.email)) ||
                (key === 'phone' && fieldsData.phone.length < 5 )){
                error[key] = true;
                result = false;
            }

        })
        setError(error);
        return result;
    }
    function sendVisit() {
        if(validate()){
            props.SendEmail(fieldsData).then(() => {
            }).finally(() => {
                setFieldsData({
                    firstName:'',
                    lastName:'',
                    email:'',
                    phone:'+374',
                    message:'',
                    services:[],
                })
            })
        }
    }
    const mapData = {
        center: [40.206645, 44.531555],
        zoom: 16,
    }

    function getInputValues(e) {
        const {name, value} = e.target
        if (name === "phone" && value && !/^[+\d]\d*$/.test(value)) {
            return
        }
        setFieldsData({
            ...fieldsData,
            [name]: value
        })
        setError({
            ...error,
            [name]: false
        })
    }

    const Options = countriesPhone.map(item => ({
        id:item.dial_code,
        name: item.code,
    }));

    const getsValues = (value) => {
        setCountry(value);
    };

    function Checked(value,name){
        setFieldsData({
            ...fieldsData,
            services:[...value]
        })
    }

    return <div className={'contacts-page-wrapper'}>
        <div className={'contacts-block'}>
            <div className={'contacts-main-block'}>
                <div className={'contacts-main-text'}>
                    <div className={'main-title'}>
                        {contacts?.title}
                    </div>
                    <div className={'main-description'}>
                        {contacts?.description}
                    </div>
                </div>
                <div className={'contacts-inputs-wrapper'}>
                    <div className={'fullName-inputs'}>
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            value={fieldsData.firstName}
                            error={error.firstName}
                            name={'firstName'}
                            placeholder={staticTexts?.contacts_page_placeholder_name}
                            label={staticTexts?.contacts_page_label_name}
                            maxLength={100}
                            onChange={getInputValues}
                        />
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            value={fieldsData.lastName}
                            error={error.lastName}
                            name={'lastName'}
                            placeholder={staticTexts?.contacts_page_placeholder_lastName}
                            label={staticTexts?.contacts_page_label_lastName}
                            maxLength={100}
                            onChange={getInputValues}
                        />
                    </div>
                    <div className={'inputs'}>
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            value={fieldsData.email}
                            error={error.email}
                            name={'email'}
                            placeholder={staticTexts?.contacts_page_placeholder_email}
                            label={staticTexts?.contacts_page_label_email}
                            maxLength={100}
                            onChange={getInputValues}
                        />
                        <span>{staticTexts?.contacts_page_placeholder_phoneNumber}</span>
                        <div className={`phones-wrapper ${error.phone ? 'invalidPhone' : ''}`}>
                            <InputGroup inputType="select"
                                        name="country"
                                        showSearch={false}
                                        value={country}
                                        onChange={(val) => getsValues(val)}
                                        options={Options}/>
                            <InputGroup
                                inputType={'input'}
                                type={"text"}
                                value={fieldsData.phone}
                                name={'phone'}
                                placeholder={staticTexts?.contacts_page_label_phoneNumber}
                                maxLength={100}
                                onChange={getInputValues}
                            />
                        </div>
                        <InputGroup
                            inputType={'textarea'}
                            type={"text"}
                            value={fieldsData.message}
                            error={error.message}
                            name={'message'}
                            placeholder={staticTexts?.contacts_page_placeholder_message}
                            label={staticTexts?.contacts_page_label_message}
                            // maxLength={100}
                            onChange={getInputValues}
                        />
                    </div>
                </div>
                <div className={'contact-services'}>
                    <div className={'contact-services-title'}>
                        {staticTexts?.contacts_page_services}
                    </div>
                    <div className={'services-block'}>
                        <Checkbox.Group
                            onChange={(e)=> Checked(e,'services')}
                            value={fieldsData.services}>
                            <Row>
                                <div className={'services-left-block'}>
                                    <Checkbox value={'63f3575affa56a401ba56e10'}>
                                        {staticTexts?.contacts_page_services_correspondence}
                                    </Checkbox>

                                    <Checkbox value={'63f3575affa56a401ba56e0e'}>
                                        {staticTexts?.contacts_page_services_call}
                                    </Checkbox>
                                </div>
                                <div className={'services-right-block'}>
                                    <Checkbox value={'63f3575affa56a401ba56e11'}>
                                        {staticTexts?.contacts_page_services_number}
                                    </Checkbox>

                                    <Checkbox value={''}>
                                        {staticTexts?.contacts_page_services_other}
                                    </Checkbox>
                                </div>
                            </Row>
                        </Checkbox.Group>
                    </div>
                </div>
                    <button className='sent-email'
                            onClick={() => {
                                if (!requestLoading && sendVisit) {
                                    sendVisit()
                                }
                            }}>
                        {staticTexts?.contacts_page_btn_send}
                        {
                            requestLoading ? <LoadingOutlined style={{fontSize: 14, color: "#fff"}}/>
                                : ''
                        }

                    </button>
            </div>
            <div className={'contact-map-block'}>
                <YMaps query={{
                    apikey: '83841bdc-892a-431f-a9ff-b378ef38c6a0',
                    lang: 'en'
                }} >
                    <Map defaultState={mapData} cursors={"inherit"} className={'yandexMap'} >
                        <Placemark
                            geometry={[40.206645, 44.531555]}
                            classname={'customIcon'}
                            options={{
                                iconLayout: 'default#image',
                                iconImageOffset: [-15, -60],
                            }}
                        />
                        <FullscreenControl options={{
                            position:{
                                bottom:28,
                                right: 26,
                            },
                        }}/>
                        <TypeSelector options={{
                            float: 'right'
                        }} />
                    </Map>
                </YMaps>
            </div>
        </div>
        <div className={'information-block'}>
            <div className={'contact-info'}>
                <a href={`mailto:${contacts?.email}`} className={'info-icon'}>
                    <ContactMessageIcon/>
                </a>
                <div className={'info-title'}>
                    {staticTexts?.contacts_page_info_bloke1_title}
                </div>
                <div className={'info-description'}>
                    {staticTexts?.contacts_page_info_bloke1_description}
                </div>
                <div className={'wrapper'}>
                    <a href={`mailto:${contacts?.email}`} className={'information'}>
                        {contacts?.email}
                    </a>
                </div>
            </div>
            <div className={'contact-info'}>
                <div className={'info-icon'}><ContactLocationIcon/></div>
                <div className={'info-title'}>
                    {staticTexts?.contacts_page_info_bloke2_title}
                </div>
                <div className={'info-description'}>
                    {staticTexts?.contacts_page_info_bloke2_description}
                </div>
                <div className={'wrapper'}>
                    <div className={'information'}>
                        {contacts?.address1}
                    </div>
                    <div className={'information'}>
                        {contacts?.address2}
                    </div>
                </div>
            </div>
            <div className={'contact-info'}>
                <a href={`tel:${contacts?.phoneNumber}`} className={'info-icon'}>
                    <ContactCallIcon/>
                </a>
                <div className={'info-title'}>
                    {staticTexts?.contacts_page_info_bloke3_title}
                </div>
                <div className={'info-description'}>
                    {staticTexts?.contacts_page_info_bloke3_description}
                </div>
                <div className={'wrapper'}>
                    <a href={`tel:${contacts?.phoneNumber}`} className={'information'}>
                        {contacts?.phoneNumber}
                    </a>
                </div>
            </div>
        </div>
        <Subscribe requestLoading={requestLoading} staticTexts={staticTexts}/>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'contacts',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
    SendEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
