// import packages
import React, {Component, useEffect, useRef} from "react";
import {connect} from "react-redux";
import {Pagination, Tabs} from 'antd';
import moment from "moment";
import InfiniteScroll from 'react-infinite-scroller';

//Import assets
import '../assets/styles/containers/blog.scss'
import {ReactComponent as ArrowIcon} from '../assets/images/icons/ic_arrow_right_green.svg';

//Import components
import {PageStart} from "../components/uiElements/PageStart";

//Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {GetCategories, GetBlogs, sendSubscribe, GetLastBlogs} from "../redux/actions";
import {Link} from "react-router-dom";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";
import {InputGroup} from "../components/uiElements/inputGroup";
import {LoadingOutlined} from "@ant-design/icons";
import {useState} from "react";
import isEmail from "validator/es/lib/isEmail";
import {PagLeftIcon, PagRightIcon, ShareIcon} from "../assets/images";
import {PAGE_GET_COUNT, PAGE_NEWS_COUNT} from "../constants/constType";
import NewsCard from "../components/uiElements/cards/newsCard/NewsCard";
import {getUrlWithLocal} from "../utils/getUrlWithLocal";


function Blog(props){
    const {staticTexts,requestLoading,blogs,lastNews} = props;
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const isFetching = useRef(false)

    useEffect(() => {
        props.GetBlogs()
        props.GetLastBlogs()
    },[])

    const getInputValues = (e) => {
        const {name, value} = e.target;
        setEmail(value);
        setError(false)
    };

    const sendSubscribe = () => {
        if (email && isEmail(email)) {
            props.sendSubscribe({email}).then(() => {
                setEmail(email)
            }, errorEmail => setError(!errorEmail))
        } else {
            setError(true)
        }
    }
    useEffect(()=> {
        setEmail("")
        setError(false)
    },[requestLoading])
    async function onPageChanged (news) {
        let count = news -1
        isFetching.current = true;
        const configInfo = {
            reset: true,
            offset: (count * PAGE_NEWS_COUNT) + PAGE_GET_COUNT,
            limit: PAGE_NEWS_COUNT,
        }
        await props.GetBlogs(configInfo)
        setCurrentPage(news);
        window.scrollTo({
            top: 0
        });
    }

    const itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return <a className={'prev_btn'}>
                <PagLeftIcon className={'arrow_icon'}/>
                <span>
                    {staticTexts?.blog_page_prev}
                </span>
            </a>;
        }
        if (type === 'next') {
            return <a className={'next_btn'}>
                <span>
                    {staticTexts?.blog_page_next}
                </span>
                <PagRightIcon className={'arrow_icon'}/>
            </a>;
        }
        return originalElement;
    };

    return <div className={'blog-wrapper'}>
        <div className={'subscribe-wrapper'}>
            <div className={'subtitle'}>
                {staticTexts?.blog_page_subscribe_subtitle}
            </div>
            <div className={'title'}>
                {staticTexts?.blog_page_subscribe_title}
            </div>
            <div className={'description'}>
                {staticTexts?.blog_page_subscribe_description}
            </div>
            <div className={'input-wrapper'}>
                <InputGroup
                    inputType={'input'}
                    type={"text"}
                    value={email}
                    error={error}
                    name={'email'}
                    placeholder={staticTexts?.homepage_contact_placeholder_email}
                    maxLength={100}
                    onChange={getInputValues}
                />

                <button className='sent-email'
                        onClick={() => {
                            if (!requestLoading && sendSubscribe) {
                                sendSubscribe()
                            }
                        }}>
                    {staticTexts?.homepage_contact_button_send}
                    {
                        requestLoading ? <LoadingOutlined style={{fontSize: 14, color: "#fff"}}/>
                            : ''
                    }

                </button>
            </div>
        </div>
        <div className={'last-news-wrapper'}>
            <div className={'last-news-title'}>
                {staticTexts?.blog_page_last_news_title}
            </div>
            {lastNews?.itemsList?.length &&
                <div className={'last-news'}>
                    <div className={`first-news-block`}>
                        <Link to={getUrlWithLocal(`/blog/${lastNews?.itemsList[0]?.slug}`)} className={`first-news`}>
                            <div className={'last-news-img'}>
                                <img src={generateImageMediaUrl(lastNews?.itemsList[0]?.mediaMain?.path)}/>
                            </div>
                            <div className={'last-news-info'}>
                                <div className={'date'}>
                                    {moment(lastNews?.itemsList[0]?.date).format("DD.MM.YYYY")}
                                </div>
                                <div className={'title-wrapper'}>
                                    <span className={'title'}>{lastNews?.itemsList[0]?.title}</span>
                                    <Link to={getUrlWithLocal(`/blog/${lastNews?.itemsList[0]?.slug}`)} className={'share'}>
                                        <ShareIcon/>
                                    </Link>
                                </div>
                                <div className={'description'}>
                                    {lastNews?.itemsList[0]?.description}
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className={`other-news-block`}>
                        <Link to={getUrlWithLocal(`/blog/${lastNews?.itemsList[1]?.slug}`)} className={`other-news`}>
                            <div className={'img-wrapper'}>
                                <div className={'last-news-img'}>
                                    <img src={generateImageMediaUrl(lastNews?.itemsList[1]?.mediaMain?.path)}/>
                                </div>
                            </div>
                            <div className={'last-news-info'}>
                                <div className={'date'}>
                                    {moment(lastNews?.itemsList[1]?.date).format("DD.MM.YYYY")}
                                </div>
                                <div className={'title-wrapper'}>
                                    <span className={'title'}>{lastNews?.itemsList[1]?.title}</span>
                                </div>
                                <div className={'description'}>
                                    {lastNews?.itemsList[1]?.description}
                                </div>
                            </div>
                        </Link>
                        <Link to={getUrlWithLocal(`/blog/${lastNews?.itemsList[2]?.slug}`)} className={`other-news`}>
                            <div className={'img-wrapper'}>
                                <div className={'last-news-img'}>
                                    <img src={generateImageMediaUrl(lastNews?.itemsList[2]?.mediaMain?.path)}/>
                                </div>
                            </div>
                            <div className={'last-news-info'}>
                                <div className={'date'}>
                                    {moment(lastNews?.itemsList[2]?.date).format("DD.MM.YYYY")}
                                </div>
                                <div className={'title-wrapper'}>
                                    <span className={'title'}>{lastNews?.itemsList[2]?.title}</span>
                                </div>
                                <div className={'description'}>
                                    {lastNews?.itemsList[2]?.description}
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            }
        </div>
        {
            blogs?.itemsList?.length ? <div className={'news-wrapper'}>
                <div className={'news-wrapper-title'}>
                    {staticTexts?.blog_page_all_news_title}
                </div>
                {blogs?.itemsList?.length &&  <div className={'news'}>
                    {blogs?.itemsList.map(item => {
                        return <NewsCard item={item} key={item.id}/>
                    })}
                </div>}
            </div> : ''
        }
            <Pagination current={currentPage}
                        onChange={onPageChanged}
                        simple={window.innerWidth < 1100 ? true : false}
                        total={blogs?.count - 3}
                        defaultPageSize={PAGE_NEWS_COUNT}
                        hideOnSinglePage={true}
                        itemRender={itemRender}
                        className={`pagination`}
            />
    </div>
}
const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'selectedLanguage',
        'staticTexts',
        'categories',
        'blogPage',
        'blogs',
        'lastNews',
    ])
};
const mapDispatchToProps = {
    GetCategories,
    GetBlogs,
    sendSubscribe,
    GetLastBlogs,
};

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
