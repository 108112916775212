// import packages
import React, {Component} from "react";
import Slider from "react-slick";

//Import styles
import "../assets/styles/homepage/partners.scss";
import {SliderNextButton, SliderPrevButton} from "./uiElements/buttons";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";

//Import Components

// Import utils

class Partners extends Component {
    constructor() {
        super();
        this.state = {
            disabledPrev: true,
            disabledNext: false,
        };
    }

    componentDidMount() {
        let itemCount = this.getShowSlideCount();
        this.setState({
            disabledNext: this.props.partners?.length - 1 < itemCount,
        });

    }

    afterSlideChange = (next) => {
        let itemCount = this.getShowSlideCount();
        this.setState({
            disabledPrev: next === 0,
            disabledNext: next + itemCount === this.props.partners?.length,
        });
    };

    getShowSlideCount = () => {
        let width = window.innerWidth;
        if (width > 1200) return 5;
        else if (width > 900) return 4;
        else if (width > 600) return 3;
        else return 2;
    };

    render() {
        const {staticTexts, partners} = this.props;
        const {disabledPrev, disabledNext} = this.state;

        const settings = {
            dots: false,
            arrows: false,
            autoplay:true,
            infinite:true,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 1,
            centerMode:  window.innerWidth < 700,
            centerPadding: '60px 0 0',
            ref: partner => (this.partners = partner),
            beforeChange: (current, next) => this.afterSlideChange(next),
            responsive: [
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        rows:3,
                    }
                },
                {
                    breakpoint: 450,
                    settings: {
                        slidesToShow: 2,
                        rows:partners?.length > 6 ? 4 : 3,
                    }
                },
            ]
        };

        return !!partners?.length && <div className="partners-wrapper">
            <div className={'partners-block'}>
                <div className="partners-title">{staticTexts?.homepage_partners_block_title}</div>

                <div className="partners-list-wrapper">
                    {
                        <Slider {...settings}>
                            {partners?.map(item => {
                                return <div  key={item} className={'partner-item-wrapper'}>
                                    <div className="partner-item" key={item.id}>
                                        <div className="partner-content">
                                            <img src={generateImageMediaUrl(item?.mediaMain?.path)} className="fade-image"
                                                 title={item?.mediaMain?.title ? item?.mediaMain?.title : ''}
                                                 alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}/>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </Slider>
                    }
                    {!!partners?.length < 5 && <>
                        <SliderPrevButton disabledPrev={disabledPrev} cbPrev={() => this.partners.slickPrev()}/>
                        {partners?.length > this.getShowSlideCount() &&
                            <SliderNextButton disabledNext={disabledNext} cbNext={() => this.partners.slickNext()}/>}
                    </>}
                </div>
                <div className={'partners-mobile-wrapper'}>
                    {partners?.map(item => {
                        return <div  key={item} className={'partner-item-wrapper'}>
                            <div className="partner-item" key={item.id}>
                                <div className="partner-content">
                                    <img src={generateImageMediaUrl(item?.mediaMain?.path)} className="fade-image"
                                         title={item?.mediaMain?.title ? item?.mediaMain?.title : ''}
                                         alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}/>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    }
}

export default Partners;
