// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

//Import assets
import '../assets/styles/homepage/contacts.scss'
import bg from '../assets/images/contact_bg.png'
import {ReactComponent as TickIcon} from '../assets/images/icons/ic_tick.svg';
import {LoadingOutlined} from '@ant-design/icons';

//Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {sendSubscribe} from "../redux/actions";
import isEmail from "validator/es/lib/isEmail";
import {InputGroup} from "./uiElements/inputGroup";

function Subscribe(props) {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const {staticTexts,requestLoading} = props;

    const getInputValues = (e) => {
        const {name, value} = e.target;
        setEmail(value);
        setError(false)
    };

    const sendSubscribe = () => {
        if (email && isEmail(email)) {
            props.sendSubscribe({email}).then(() => {
                setEmail(email)
            }, errorEmail => setError(!errorEmail))
        } else {
            setError(true)
        }
    }
    useEffect(()=> {
        setEmail("")
        setError(false)
    },[requestLoading])
    return <div className="contacts-wrapper">
            <div className="form-content">
                <div className={'contacts-info-block'}>
                    <div className="title">{staticTexts?.homepage_contact_title}</div>
                    <div className="description">{staticTexts?.homepage_contact_description}</div>
                </div>
                <div className="input-wrapper">
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        value={email}
                        error={error}
                        name={'email'}
                        placeholder={staticTexts?.homepage_contact_placeholder_email}
                        maxLength={100}
                        onChange={getInputValues}
                    />

                    <button className='sent-email'
                            onClick={() => {
                                if (!requestLoading && sendSubscribe) {
                                    sendSubscribe()
                                }
                            }}>
                        {staticTexts?.homepage_contact_button_send}
                        {
                           requestLoading ? <LoadingOutlined style={{fontSize: 14, color: "#fff"}}/>
                                : ''
                        }

                    </button>
                </div>
            </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, ['staticTexts'])
};

const mapDispatchToProps = {
    sendSubscribe,
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscribe);