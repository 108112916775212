// Import packages
import React from "react";
import {store} from "../../redux/store";

// Import utils
import {history} from "../../configs/history";

// Import styles
import "../../assets/styles/components/uiElements/buttons.scss";
import {ReactComponent as ArrowLeft} from '../../assets/images/icons/ic_arow_white.svg';
import {PagLeftIcon, PagRightIcon} from "../../assets/images";

// import {LoadingOutlined} from '@ant-design/icons';

export function LinkButton(props) {
    const {title, className, link, cb, loading, disabled, loadingText} = props;
    return <button className={`link-button ${className ? className : ''}`}
                   disabled={disabled || false}
                   onClick={() => {
                       if (link) {
                           history.push(link)
                       } else {
                           if (!loading && cb) {
                               cb();
                           }
                       }
                   }}>
        {loading ?
            <div className="flex-row">{loadingText}
                {/*<LoadingOutlined style={{fontSize: 14, color: "#fff"}}/>*/}
            </div> :
            <span className="title">{title}</span>}
    </button>
}

export function SliderButton(props) {
    const {className, cbPrev, cbNext, disabledPrev, disabledNext} = props;
    return <div className={`arrows ${className}`}>
       {!disabledPrev && <div className="prev-btn" onClick={cbPrev}>
           <ArrowLeft title={''} className={'prev-icon'}/>
            {/*<img src={require('../../assets/images/icons/ic_arow.svg')}*/}
            {/*     className={'prev-icon'} alt=""/>*/}
        </div>}
       {!disabledNext && <div className="next-btn" onClick={cbNext}>
           <ArrowLeft title={''} className={'prev-icon'}/>
            {/*<img src={require('../../assets/images/icons/ic_arow.svg')}*/}
            {/*     className={'next-icon'} alt=""/>*/}
        </div>}
    </div>
}

export function SliderPrevButton(props) {
    const {className, cbPrev, disabledPrev} = props;
    return !disabledPrev && <div className={`prev-btn ${className}`} onClick={cbPrev}>
        <PagRightIcon title={''} className={'prev-icon'}/>
        {/*<img src={require('../../assets/images/icons/ic_arow.svg')}*/}
        {/*     className={'prev-icon'} alt=""/>*/}
    </div>
}

export function SliderNextButton(props) {
    const {className,  cbNext, disabledNext} = props;
    return !disabledNext && <div className={`next-btn ${className}`} onClick={cbNext}>
        <PagRightIcon title={''} className={'prev-icon'}/>
        {/*<img src={require('../../assets/images/icons/ic_arow.svg')}*/}
        {/*     className={'next-icon'} alt=""/>*/}
    </div>
}