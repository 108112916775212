// import packages
import React from 'react';
import isEmail from "validator/es/lib/isEmail";
import {useState} from "react";

//Import Components
import {InputGroup} from "../uiElements/inputGroup";
import {REQUEST_TYPE} from "../../constants/defaultTexts";

//Import assets
import "./callModel.scss"
import {Checkbox, Modal, TimePicker,Select} from 'antd';

// Import utils
import {connect} from "react-redux";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {callRequest} from "../../redux/actions";



function CallModel(props){
    const {toggleCallBtn,callModelOpen,staticTexts} = props

    const SELECT_SERVICE = {
        finance:{
            id:"FINANCE",
            name:staticTexts?.financial_text
        },
        business:{
            id:"BUSINESS",
            name:staticTexts?.business_text
        },
        accounting:{
            id:"ACCOUNTING",
            name:staticTexts?.accounting_text
        },
        jurisprudence:{
            id:"LAW",
            name:staticTexts?.jurisprudence_text
        },
    }

    const initialValue = {
        fullName: "",
        phoneNumber: "",
        email: "",
        service: undefined,
        time:"",
        callNearestPeriod:false,
    }

    const { Option } = Select;

    const [showTime,setShowTime] = useState(false)
    const [timeChecked,setTimeChecked] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [confirmBtnDisable, setConfirmBtnDisable] = useState(false);
    const [modalTitleType, setModalTitleType] = useState("");
    /*todo callData-ի և errors-ի համար initialValue պահի, closeModal-ում էլ դա օգտագործի */
    const [callData,setCallData] = useState(initialValue)
    const [errors,setErrors]=useState(initialValue)
    function validation() {
        let errors = {}
        let result = true
        if(!callData.fullName){
            errors.fullName =true;
            result = true
        }
        if(!callData.service){
            errors.service =true;
            result = true
        }
        if(!isEmail(callData.email)){
            errors.email =true;
            result = true
        }
        if(callData.phoneNumber.length < 9){
            errors.phoneNumber =true;
            result = true
        }
        if(!callData.time && !callData.callNearestPeriod){
            errors.time =true;
            errors.callNearestPeriod = true;
            result = true
        }
        setErrors(errors);
        return result;
    }

    const serviceValue = Object.values(SELECT_SERVICE).map(value => value)


/*todo closeModel -> closeModal*/
    function closeModal(){
        toggleCallBtn()
        setCallData(initialValue)
        setErrors(initialValue)
        setShowTime(false)
        setTimeChecked(false)

    }

    function sendCallRequest() {
        setCallData({...callData,...callData.callNearestPeriod = timeChecked})
       if(validation()){
           setConfirmLoading(true);
           let reqData = {
               ...callData
           }
           if(timeChecked){
                delete reqData.time
           }else if( reqData.time){
               delete reqData.callNearestPeriod
           }
           props.callRequest(reqData).then(() =>{
               setTimeout(() => {
                   setConfirmLoading(false);
                   setModalTitleType(REQUEST_TYPE.success);
                   setConfirmBtnDisable(true)
               }, 2000)
               setTimeout(() => {
                   closeModal()
                   setConfirmLoading(false);
                   setModalTitleType(REQUEST_TYPE.title);
                   setConfirmBtnDisable(false)
               }, 2700)
           }).catch((err) => {
               setConfirmLoading(false);
        })
       }
    }


/*todo saveInputValue և  saveValue ֆունկցիաները նույն բանն են անում, պետտք է օգտագործել մեկը*/

    function saveValue({name,value}){
        if (name === "phoneNumber" && value && !/^[+\d]\d*$/.test(value)) {
            return
        }
        setCallData({
            ...callData,
            [name]:value,
        })
        setErrors({
            ...errors,
            [name]: false,
        })
    }

    function getDisabledHours() {
        let hours = [];
        for (let i = 0; i < 24; i++) {
            if (i < 9 || i > 18) {
                hours.push(i);
            }
        }
        return hours;
    }

    const title = modalTitleType === REQUEST_TYPE.success ? staticTexts?.success_title_text : (
        modalTitleType === REQUEST_TYPE.error ? staticTexts?.error_title_text : staticTexts?.call_model_title
    )

    return <Modal centered
                  visible={callModelOpen}
                  title={title}
                  okText={staticTexts?.send_text}
                  confirmLoading={confirmLoading}
                  onOk={!confirmBtnDisable && sendCallRequest}
                  onCancel={closeModal}>
        <div className={'first-block'}>
            <InputGroup
                inputType={'input'}
                type={"text"}
                value={callData.fullName}
                error={errors.fullName}
                name={'fullName'}
                placeholder={staticTexts?.full_name_placeholder}
                maxLength={100}
                onChange={(e) => saveValue({name:'fullName', value:e.target.value})}/>
            <InputGroup
                inputType={'input'}
                type={"text"}
                value={callData.phoneNumber}
                error={errors.phoneNumber}
                name={'phoneNumber'}
                placeholder={staticTexts?.number_placeholder}
                maxLength={20}
                onChange={(e) => saveValue({name:'phoneNumber', value:e.target.value})}/>
        </div>
        {/*todo InputGroup կոմպոնենտի մեջ ունենք select դա օգտագործի*/}
        <div className={"second-block"}>
            <InputGroup
                inputType={'input'}
                type={"text"}
                value={callData.email}
                error={errors.email}
                name={'email'}
                placeholder={staticTexts?.email_placeholder}
                maxLength={50}
                onChange={(e) => saveValue({name:'email', value:e.target.value})}/>

            <InputGroup
                showSearch={false}
                inputType={'select'}
                className={`${errors.service? 'err' : "valid"}`}
                value={callData.service}
                name={'service'}
                error={errors.service}
                placeholder={staticTexts?.service_placeholder}
                onChange={(value) => saveValue({name:'service', value:value})}
                options={serviceValue}/>
        </div>
        <div className={'checked-block'}>
            <div className={'timeCheckbox'}>
                <Checkbox onChange={() => setTimeChecked(!timeChecked)}
                          checked={timeChecked}
                          className={`${errors.callNearestPeriod && !showTime ? 'errBorder' : ""}`}
                          disabled={showTime}>
                    {staticTexts?.call_shortly_text}
                </Checkbox>
            </div>

            {showTime ?  <InputGroup inputType={'wrapper'}
                                     className={`${errors.time ? 'errors' : ""}`}
                                     value={callData.time}>
                <TimePicker allowClear={false}
                            disabledHours={getDisabledHours}
                            placeholder={staticTexts?.call_time_picker_placeholder}
                            minuteStep={10}
                            value={callData.time}
                            format={'HH:mm'}
                            className={`${errors.time ? 'error' : ""}`}
                            popupClassName="popup-input-time"
                            onSelect={(time) => saveValue({name: 'time', value: time})}/>
            </InputGroup> :
                <div className={'checkboxWrapper'}>
                    <Checkbox onChange={() => setShowTime(!showTime)}
                              checked={showTime}
                              disabled={timeChecked}
                              className={`${errors.time && !timeChecked ? 'error' : ""}`}>
                        {staticTexts?.choose_time_text}
                    </Checkbox>
                </div>
            }
        </div>
    </Modal>
}
const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
    callRequest,
};
export default connect(mapStateToProps, mapDispatchToProps)(CallModel)