// import packages
import React, {Component} from "react";
import Slider from "react-slick";

//Import styles
import "./newsSlider.scss";
import {SliderNextButton, SliderPrevButton} from "../buttons";
import NewsCard from "../cards/newsCard/NewsCard";

//Import Components

// Import utils

class NewsSlider extends Component {
    constructor() {
        super();
        this.state = {
            disabledPrev: true,
            disabledNext: false,
        };
    }

    componentDidMount() {
        let itemCount = this.getShowSlideCount();
        this.setState({
            disabledNext: this.props.blog?.length - 1 < itemCount,
        });

    }

    afterSlideChange = (next) => {
        let itemCount = this.getShowSlideCount();
        this.setState({
            disabledPrev: next === 0,
            disabledNext: next + itemCount === this.props.blog?.length,
        });
    };

    getShowSlideCount = () => {
        let width = window.innerWidth;
        if (width > 1200) return 5;
        else if (width > 900) return 4;
        else if (width > 600) return 3;
        else return 2;
    };

    render() {
        const {blog} = this.props;
        const {disabledPrev, disabledNext} = this.state;

        const settings = {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 1000,
            autoPlay:true,
            slidesToShow: 4,
            slidesToScroll: 1,
            // centerMode:  true,
            // centerPadding: '20px 0 0',
            ref: blogs => (this.blog = blogs),
            beforeChange: (current, next) => this.afterSlideChange(next),
            responsive: [
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        centerPadding: '40px 0 0',
                    }
                },
                {
                    breakpoint: 450,
                    settings: {
                        slidesToShow: 1,
                        centerPadding: '20px 0 0',
                    }
                },
            ]
        };

        return !!blog?.length && <div className="blog-slider-wrapper">
            <div className="blog-list-wrapper">
                {
                    <Slider {...settings} className={'news'}>
                        {blog?.map(item => {
                            return <NewsCard key={item}
                                             item={item}/>
                        })}
                    </Slider>
                }
                {<div className={'slider-arr'}>
                    <SliderPrevButton  cbPrev={() => this.blog.slickPrev()}/>
                    {<SliderNextButton  cbNext={() => this.blog.slickNext()}/>}
                </div>}
            </div>
        </div>
    }
}

export default NewsSlider;
