import React from "react";

//Import Assets
import "./membersCard.scss";
import {generateImageMediaUrl} from "../../../../utils/generateMediaUrl";
import empty_img from '../../../../assets/images/icons/user.png'
import {MembersIcon} from "../../../../assets/images";

function MembersCard({item}){

    return <div className={'members-card-wrapper'}>
        {item?.mediaMain ?
            <div className={'img-block'}>
                <div className={'members-img-wrapper'}>
                    <img src={generateImageMediaUrl(item?.mediaMain?.path)}/>
                </div>
            </div>
            : <div className={'empty-wrapper'}>
                <div className={'empty-img'}>
                    <img src={empty_img} alt=""/>
                </div>
             </div>
        }
        <div className={'members-name'}>
            {item?.name}
        </div>
        <div className={'members-position'}>
            {item?.position}
        </div>
    </div>

}
export default MembersCard