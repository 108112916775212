// import packages
import React, {useEffect} from "react";
import {connect} from "react-redux";

//Import assets
import '../assets/styles/containers/about.scss'

//Import components
import Partners from "../components/Partners";

//Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";
import {GetAbout} from "../redux/actions";
import Subscribe from "../components/Contacts";


function About(props) {
    const {about,staticTexts,partners} = props

    useEffect(() => {
        props.GetAbout()
    },[])
    return <div className={'about-wrapper'}>
        <div className={'about-block'}>

        </div>
        <div className={'about-main-wrapper'}>
            <div className={'about-main'}>
                <div className={'about-main-subtitle'}>
                    {about?.pageName}
                </div>
                <div className={'about-main-title'}>
                    {about?.title}
                </div>
                <div className={'about-main-description'}>
                    {about?.description}
                </div>
            </div>
        </div>
        <Partners staticTexts={staticTexts} partners={partners?.partners}/>

        {about?.blocks?.length && <div className={'blocks-wrapper'}>
            {about?.blocks.map(item => {
                return <div className={'blocks'} key={item?.id}>
                    <div className={'blocks-img'}>
                        <img src={generateImageMediaUrl(item?.mediaMain?.path)}/>
                    </div>
                    <div className={'blocks-text'}>
                        <div className={'blocks-description'} dangerouslySetInnerHTML={{__html: item?.description}}/>
                    </div>
                </div>
            })}
        </div>}

        <div className={'additional-info-block'}>
            <div className={'additional-info-wrapper'}>
                <div className={'additional-info-img'}>
                    <img src={generateImageMediaUrl(about?.media?.path)}/>
                </div>
                <div className={'additional-info'}>
                    <div className={'additional-info-title'}>
                        {about?.subTitle}
                    </div>
                    <div className={'additional-info-description'}>
                        {about?.subDescription}
                    </div>
                    <div className={'info-block'}>
                        <div className={'left-info-block'}>
                            <div className={'some-info'}>
                                <span className={'parametric'}>{about?.availability}</span>
                                <span className={'parametric-text'}>{staticTexts?.homepage_about_availability_title}</span>
                            </div>
                            <div className={'some-info'}>
                                <span className={'parametric'}>{about?.workers}</span>
                                <span className={'parametric-text'}>{staticTexts?.homepage_about_employee_title}</span>
                            </div>
                        </div>
                        <div className={'right-info-block'}>
                            <div className={'some-info'}>
                                <span className={'parametric'}>{about?.service}</span>
                                <span className={'parametric-text'}>{staticTexts?.homepage_about_service_title}</span>
                            </div>
                            <div className={'some-info'}>
                                <span className={'parametric'}>{about?.inMarket}</span>
                                <span className={'parametric-text'}>{staticTexts?.homepage_about_years_title}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Subscribe/>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'about',
        'staticTexts',
        'partners',
    ])
};
const mapDispatchToProps = {
    GetAbout,
};

export default connect(mapStateToProps,mapDispatchToProps)(About);