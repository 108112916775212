// import packages
import React, {Component, useEffect} from "react";
import {connect} from "react-redux";

//Import assets
import '../assets/styles/containers/services.scss'
import mainImg from '../assets/images/Group 33.png';

//Import components
import {PageStart} from "../components/uiElements/PageStart";

//Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {CALENDAR_LINK} from "../constants/constType";
import {ResponsiveBgIcon, ServicesBgIcon, ServicesResponsiveBgIcon} from "../assets/images";
import {useParams} from "react-router-dom";
import {GetServicesById} from "../redux/actions";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";
import Consultation from "../components/homepage/Consultation";
import Subscribe from "../components/Contacts";

function Services(props){
    const params = useParams()
    useEffect(() => {
        props.GetServicesById(params.slug)
    },[params.slug, props.selectedLanguage?.code])
    const {staticTexts,servicesById} = props
   return <div className={'services-page-wrapper'}>
       {
           (params?.slug === 'messaging') ? <div className={'services-main-block'}>
               <div className={'services-main'}>
                   <div className={'main-info'}>
                       <div className={'responsiveBg-block'}>
                           <ResponsiveBgIcon/>
                       </div>
                       <div className={'main-title'}>
                           {staticTexts?.service_messaging_main_title}
                       </div>
                       <div className={'main-description'}>
                           {staticTexts?.service_messaging_main_description}
                       </div>
                       <a className={'main-consultation'} href={CALENDAR_LINK} target={'_blank'}>
                           {staticTexts?.homepage_consultation_btn_title}
                       </a>
                   </div>
                   <span className={'bg-block'}/>
                   <div className={'img-block'}>
                       <img src={generateImageMediaUrl(servicesById?.mediaMain?.path)}/>
                   </div>
               </div>
               </div> :
               (params?.slug === 'short-number') ? <div className={'services-main-block'}>
                      <div className={'services-main'}>
                          <div className={'main-info'}>
                              <div className={'responsiveBg-block'}>
                                  <ResponsiveBgIcon/>
                              </div>
                              <div className={'main-title'}>
                                  {staticTexts?.service_shortNumber_main_title}
                              </div>
                              <div className={'main-description'}>
                                  {staticTexts?.service_shortNumber_main_description}
                              </div>
                              <a className={'main-consultation'} href={CALENDAR_LINK} target={'_blank'}>
                                  {staticTexts?.homepage_consultation_btn_title}
                              </a>
                          </div>
                          <span className={'bg-block'}/>
                          <div className={'img-block'}>
                              <img src={generateImageMediaUrl(servicesById?.mediaMain?.path)}/>
                          </div>
                      </div>
                   </div> :
                   (params?.slug === 'call-center') ? <div className={'services-main-block'}>
                       <div className={'services-main'}>
                           <div className={'main-info'}>
                               <div className={'responsiveBg-block'}>
                                   <ResponsiveBgIcon/>
                               </div>
                               <div className={'main-title'}>
                                   {staticTexts?.service_callCenter_main_title}
                               </div>
                               <div className={'main-description'}>
                                   {staticTexts?.service_callCenter_main_description}
                               </div>
                               <a className={'main-consultation'} href={CALENDAR_LINK} target={'_blank'}>
                                   {staticTexts?.homepage_consultation_btn_title}
                               </a>
                           </div>
                           <span className={'bg-block'}/>
                           <div className={'img-block'}>
                               <img src={generateImageMediaUrl(servicesById?.mediaMain?.path)}/>
                           </div>
                       </div>
                   </div> : ''
       }
       <div className={'service-page-block'}>

           {
               (params?.slug === 'messaging') ? <div className={'services-features-block'}>
                       <div className={'services-features-title'}>
                           {staticTexts?.service_messaging_features_title}
                       </div>
                       <div className={'services-features-text'}>
                           {staticTexts?.service_messaging_features_description}
                       </div>
                   </div> :
                   (params?.slug === 'short-number') ? <div className={'services-features-block'}>
                           <div className={'services-features-title'}>
                               {staticTexts?.service_shortNumber_features_title}
                           </div>
                           <div className={'services-features-text'}>
                               {staticTexts?.service_shortNumber_features_description}
                           </div>
                       </div> :
                       (params?.slug === 'call-center') ? <div className={'services-features-block'}>
                           <div className={'services-features-title'}>
                               {staticTexts?.service_callCenter_features_title}
                           </div>
                           <div className={'services-features-text'}>
                               {staticTexts?.service_callCenter_features_description}
                           </div>
                       </div>: ''

           }

           {servicesById?.features?.length && <div className={'features-wrapper'}>
               {servicesById?.features.map(item => {
                   return <div className={'feature'} key={item}>
                       <div className={'feature-img'}>
                           <img src={generateImageMediaUrl(item?.mediaMain?.path)}/>
                       </div>
                       <div className={'feature-title'}>
                           {item?.title}
                       </div>
                       <div className={'feature-description'} dangerouslySetInnerHTML={{__html: item?.description}}/>
                   </div>
               })}
           </div>
           }

           {servicesById?.blocks?.length && <div className={'blocks-wrapper'}>
               {servicesById?.blocks.map(item => {
                   return <div className={'blocks'} key={item}>
                       <div className={'blocks-img'}>
                           <img src={generateImageMediaUrl(item?.mediaMain?.path)}/>
                       </div>
                       <div className={'blocks-text'}>
                           <div className={'bg-icon'}>
                               <ServicesBgIcon/>
                           </div>
                           <div className={'responsive-bg-icon'}>
                               <ServicesResponsiveBgIcon/>
                           </div>
                           <div className={'blocks-title'}>
                               {item?.title}
                           </div>
                           <div className={'blocks-description'} dangerouslySetInnerHTML={{__html: item?.description}}/>
                       </div>
                   </div>
               })}
           </div>}
       </div>

       <Consultation staticTexts={staticTexts}/>
       <Subscribe/>
   </div>
}
const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'services',
        'staticTexts',
        'servicesById',
        'selectedLanguage',
    ])
};
const mapDispatchToProps = {
    GetServicesById,
};
export default connect(mapStateToProps,mapDispatchToProps)(Services);
