// Import packages
import React, {memo} from "react";
import {connect} from 'react-redux';
import {Link, NavLink} from "react-router-dom";

// Import assets
import '../assets/styles/components/right-menu.scss';

// Import components
import {getPropsFromState} from "../redux/mapStateToProps";
import {Dropdown, Menu} from "antd";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";
import {ReactComponent as LngIcon} from "../assets/images/icons/ic_lng_arrow.svg";
import {ChangeLanguage} from "../redux/actions";
import {getUrlWithLocal} from "../utils/getUrlWithLocal";
import {history} from "../configs/history";

// Import utils



const RightMenu = memo((props) => {
    const {className, staticTexts, toggleMobileMenu,languages,selectedLanguage,services} = props;


    return <section className={`right-menu-wrapper ${className}`}>
        <div className={`right-menu-content ${className}`}>
            <nav className='mobile-nav'>
                <NavLink to={getUrlWithLocal('/home')} activeClassName='active'>
                    <div className="nav-item">{staticTexts?.header_item_home}</div>
                </NavLink>
                <div className="nav-item">{staticTexts?.header_item_services}</div>
                <ul className={'header-services'}>
                    {services?.length && services.map(item => {
                        return <li>
                            <NavLink to={getUrlWithLocal(`/services/${item?.slug}`)} activeClassName='active'>
                                {item?.title}
                            </NavLink>
                        </li>
                    })}
                </ul>
                <NavLink to={getUrlWithLocal('/about')} activeClassName='active'>
                    <div className="nav-item">{staticTexts?.header_item_about}</div>
                </NavLink>
                <NavLink to={getUrlWithLocal('/blog')} activeClassName='active'>
                    <div className="nav-item">{staticTexts?.header_item_news}</div>
                </NavLink>
                <NavLink to={getUrlWithLocal('/team')} activeClassName='active'>
                    <div className="nav-item">{staticTexts?.header_item_staff}</div>
                </NavLink>
                <NavLink to={getUrlWithLocal('/contacts')} activeClassName='active'>
                    <div className="nav-item">{staticTexts?.header_item_contact}</div>
                </NavLink>
                <div className="language-items">
                    {
                        languages?.length && languages?.map((item) => {
                            return <div key={item.id}
                                        onClick={() => {
                                            history.push(`/${item.code}${history.location?.pathname?.slice(3)}`)
                                            props.ChangeLanguage(item)
                                        }}
                                           className={'language-dropdown-item'}>
                                    <div className={`selected-language 
                                    ${selectedLanguage?.code === item.code ? 'active-language' : ''}`}>
                                       {item?.name}
                                    </div>
                                </div>
                        })
                    }
                </div>
            </nav>
        </div>
        <div className={`mobile-menu-overlay`} onClick={toggleMobileMenu}/>
    </section>
})

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'contacts',
        'languages',
        'selectedLanguage',
        'services',
    ])
};
const mapDispatchToProps = {
    ChangeLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(RightMenu);