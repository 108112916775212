/** Google map key */
export const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;

/** Auth client Id and Secret */
// export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
// export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;

/** Auth google client Id */
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

/** Auth facebook app Id */
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;