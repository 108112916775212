// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

//import assets
import '../assets/styles/components/header.scss'
import {ReactComponent as Logo} from '../assets/images/Logo.svg';

// Import Components

// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {Link, NavLink, useLocation} from "react-router-dom";
import {ChangeLanguage} from "../redux/actions";
import {Dropdown, Menu, Space} from "antd";
import {generateFileMediaUrl, generateImageMediaUrl} from "../utils/generateMediaUrl";
import {ReactComponent as LngIcon} from "../assets/images/icons/ic_lng_arrow.svg";
import {BurgerIcon, CloseIcon, DropdownServicesIcon, MoreArrIcon} from "../assets/images";
import {CALENDAR_LINK} from "../constants/constType";
import {getUrlWithLocal} from "../utils/getUrlWithLocal";
import {history} from "../configs/history";

function Header(props) {
    const {staticTexts,selectedLanguage,languages,toggleLanguage,changeLanguage,services} = props;
    // const [closeLanguage, setCloseLanguage] = useState(false)
    // useEffect(() => {
    //     window.addEventListener("scroll",() => setCloseLanguage(true))
    // },[])
    let location = useLocation()

    const menuLanguage = (
        <Menu className={`languages-dropdown`}>
            {
                languages?.length && languages?.map((item) => {
                    return selectedLanguage?.code !== item.code &&
                        <Menu.Item key={item.id}
                                   onClick={() => {
                                       history.push(`/${item.code}${history.location?.pathname?.slice(3)}`)
                                       props.ChangeLanguage(item)
                                   }}
                                   className={'language-dropdown-item'}>
                            <div className="selected-language">
                                <span>{item?.name}</span>
                            </div>
                        </Menu.Item>
                })
            }
        </Menu>
    );

    console.log(generateImageMediaUrl(selectedLanguage?.iconPath?.path),'selectedLanguage')
    const openServices = (
        <Menu className="services-dropdown">
            <div className={'services-info-block'}>
                <DropdownServicesIcon/>

                <div className={'services-info'}>
                    <div className={'info-title'}>
                        {staticTexts?.homepage_consultation_title}
                    </div>
                    <div className={'info-description'}>
                        {staticTexts?.homepage_consultation_description}
                    </div>
                    <a href={CALENDAR_LINK} target={'_blank'} className={'info-link'}>
                        {staticTexts?.homepage_consultation_btn_title}
                    </a>
                </div>
            </div>
                <div className={'services-block'}>
                    {
                        services?.length && services?.map((item) => {
                            return <Menu.Item key={item.id} className={'services-dropdown-item'}>
                                <Link to={getUrlWithLocal(`/services/${item?.slug}`)} className="dropdown-items">
                                    <span className={'item-title'}>{item?.title}</span>
                                    <span className={'item-description'}>{item?.descriptions[0]}</span>
                                    <span className={'item-description'}>{item?.descriptions[1]}</span>
                                    <span className={'item-description'}>{item?.descriptions[2]}</span>

                                    <div className={'item-more-info'}>
                                        {staticTexts?.homepage_more_info_btn} <MoreArrIcon/>
                                    </div>
                                </Link>
                            </Menu.Item>
                        })
                    }
                </div>
        </Menu>
    );
    let fixe = props.mobileMenuIsOpen
    let hide = location.pathname.includes('/home') || location.pathname.includes('/services')
    return <div className={`header-wrapper ${hide ? 'color' : ''} ${fixe ? 'fixe' : ''}`}>
       <div className={'header-block'}>
           {
               location.pathname.includes('/short-number')  || location.pathname.includes('/call-center')
               || location.pathname.includes('/messaging')  ? <span className={'bg-block'}/> : ''
           }
           <Link to={'/'}>
               <div className={'ask-logo'}>
                   <Logo title={''}/>
               </div>
           </Link>
           <ul className="nav-menu">
               <NavLink to={getUrlWithLocal('/home')} activeClassName='active'>
                   <li className="nav-item">{staticTexts?.header_item_home}</li>
               </NavLink>
               <li className="nav-item">
                   <Dropdown
                       overlay={openServices}
                       trigger={['hover']}
                       destroyPopupOnHide={false}
                       autoFocus={false}
                       autoAdjustOverflow={false}
                   >
                       <a onClick={(e) => e.preventDefault()}>
                           <Space>
                               {staticTexts?.header_item_services}
                           </Space>
                       </a>
                   </Dropdown>
               </li>
               <NavLink to={getUrlWithLocal('/about')} activeClassName='active'>
                   <li className="nav-item">{staticTexts?.header_item_about}</li>
               </NavLink>
               <NavLink to={getUrlWithLocal('/blog')} activeClassName='active'>
                   <li className="nav-item">{staticTexts?.header_item_news}</li>
               </NavLink>
               <NavLink to={getUrlWithLocal('/team')} activeClassName='active'>
                   <li className="nav-item">{staticTexts?.header_item_staff}</li>
               </NavLink>
               <NavLink to={getUrlWithLocal('/contacts')} activeClassName='active'>
                   <li className="nav-item">{staticTexts?.header_item_contact}</li>
               </NavLink>

               <li className="nav-item">
                   <Dropdown overlay={menuLanguage}
                             trigger={['hover']}
                             // overlayClassName={`${closeLanguage ? "close" : ""}`}
                             // onClick={() => toggleLanguage(setCloseLanguage(false))}
                             placement={'bottomCenter'}>
                       <a className="ant-dropdown-link">
                           <div className="selected-language">
                               <div className={'language_icon'}>
                                   <img src={generateImageMediaUrl(selectedLanguage?.iconPath?.path)}/>
                               </div>
                               <div>
                               </div>
                           </div>
                       </a>
                   </Dropdown>
               </li>
               <a className={`header_burger ${!props.mobileMenuIsOpen ? 'open-menu' : 'close-menu'}`}
                  onClick={props.toggleMobileMenu}>
                   {!props.mobileMenuIsOpen ? <BurgerIcon/> : <CloseIcon/>}
               </a>
           </ul>
       </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'languages',
        'selectedLanguage',
        'services',
    ])
};
const mapDispatchToProps = {ChangeLanguage};
export default connect(mapStateToProps,mapDispatchToProps)(Header);
