// import packages
import React from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom'

// Import assets
import '../assets/styles/components/footer.scss'
import logo from '../assets/images/LogoWhite.svg'
import { ReactComponent as CompanyIcon } from '../assets/images/4steps.svg';
import { ReactComponent as FbIcon } from '../assets/images/icons/ic_facebook.svg';
import { ReactComponent as LinkedinIcon } from '../assets/images/icons/ic_linkedin.svg';
import { ReactComponent as CollapseIcon } from "../assets/images/icons/ic_arrow_right_green.svg";

// Import utils
import { getPropsFromState } from "../redux/mapStateToProps";
import { GetContacts } from "../redux/actions";
import { Collapse } from 'antd';
import { ReactComponent as Logo } from '../assets/images/Logo.svg';
import { FooterLogoIcon } from "../assets/images";
import { generateFileMediaUrl } from "../utils/generateMediaUrl";

// const {Panel} = Collapse;

const { Panel } = Collapse;

function Footer (props) {
    const { contacts, staticTexts, } = props;

    function downloadPresentation () {
        const file = contacts?.presentationFile;
        if (file) {
            fetch(generateFileMediaUrl(file?.path))
                .then(response => {
                    response.blob().then(blob => {
                        let a = document.createElement('a');
                        a.href = window.URL.createObjectURL(blob);
                        a.download = `presentation.${file.extension}`;
                        a.click();
                    });
                });
        }
    }

    return <footer className='footer-wrapper'>
        <div className="desktop-footer-wrapper">
            <div className="top-part">
                <div className="col">
                    <div className="logo-wrapper">
                        <Logo title={''}/>
                    </div>
                    <div className={'text-wrapper'}>
                        {staticTexts?.footer_section_description}
                    </div>
                    <div className="icons-wrapper">
                        <a href={contacts?.facebookUrl} target={'_blank'}>
                            Facebook
                        </a>
                        <a href={contacts?.linkedinUrl} target={'_blank'}>
                            Linkedin
                        </a>
                    </div>
                </div>
                {contacts?.presentationFile &&
                    <div className={'text-wrapper presentation-button'} onClick={downloadPresentation}>
                    {staticTexts?.footer_section_button_presentation || 'Ներբեռնեք ներկայացումը'}
                </div>}
                <div className="col">
                    <div className="title">{staticTexts?.footer_section_sites_title}</div>
                    <Link to={`/`}>{staticTexts?.footer_section_home}</Link>
                    <Link to={`/about`}>{staticTexts?.footer_section_about}</Link>
                    <Link to={`/blog`}>{staticTexts?.footer_section_news}</Link>
                    <Link to={`/team`}>{staticTexts?.footer_section_staff}</Link>
                    <Link to={`/contacts`}>{staticTexts?.footer_section_contact}</Link>
                </div>
                <div className="col">
                    <div className="title">{staticTexts?.footer_section_services_title}</div>
                    <Link to={`/services/messaging`}>{staticTexts?.footer_section_correspond}</Link>
                    <Link to={`/services/short-number`}>{staticTexts?.footer_section_number}</Link>
                    <Link to={`/services/call-center`}>{staticTexts?.footer_section_call}</Link>

                </div>
                <div className="col">
                    <div className="title">{staticTexts?.footer_section_projects_title}</div>
                    <a href={`https://mobileid.am/`} target={'_blank'}>{staticTexts?.footer_section_MobileID}</a>
                    <a href={`https://www.fortunecup.am/`} target={'_blank'}>{staticTexts?.footer_section_Fortune}</a>
                    {/*<Link to={`/`}>{staticTexts?.footer_section_Login}</Link>*/}
                    <Link to={`/`}>{staticTexts?.footer_section_Promos}</Link>
                </div>
            </div>

            <div className="bottom-part">
                <div className="copyright">{staticTexts?.footer_section_copyright_text}</div>
                <div className='developed-by'>
                    <div className={'develop'}>
                        {staticTexts?.footer_section_developed_by_text}
                        <a href={"https://solidy.am/"} target={'_blank'} className={'footer-logo'}>
                            <FooterLogoIcon/>
                        </a>
                    </div>
                    <div className={'design'}>
                        {staticTexts?.footer_section_developed_by_text} <p>Ham’n’Jam</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>

}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'contacts',
        'selectedLanguage',
    ])
};

const mapDispatchToProps = { GetContacts };

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
