// import packages
import React from "react";

//Import assets
import '../../assets/styles/homepage/consultation.scss'
import {CALENDAR_LINK} from "../../constants/constType";
import {ConsultationBgIcon, DropdownResponseBgIcon, ResponsiveConsultationBgIcon} from "../../assets/images";



function Consultation({staticTexts}){
    return <div className={'consultation-wrapper'}>
        <div className={'background-wrapper'}>
            <ConsultationBgIcon/>
        </div>
        <div className={'consultation'}>
            <div className={'consultation-title'}>
                {staticTexts?.homepage_consultation_title}
            </div>
            <div className={'consultation-description'}>
                {staticTexts?.homepage_consultation_description}
            </div>
            <a href={CALENDAR_LINK} target={'_blank'} className={'consultation-link'}>
                {staticTexts?.homepage_consultation_btn_title}
            </a>
            <div className={'responsive-wrapper'}>
                <ResponsiveConsultationBgIcon/>
            </div>
            <div className={'mobile-block'}>
                <DropdownResponseBgIcon/>
            </div>
        </div>
    </div>
}
export default Consultation